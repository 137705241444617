<template>
  <b-row class="promotion-page">
    <b-col>
      <PageHeader :title="$t('general.promo.title')">
        <div class="promotion-page__header">
          <button
            type="button"
            @click="() => {}"
            class="collections-header-button__button"
          >
            <CIcon name="question" class="collections-header-button__icon" />
          </button>
        </div>
      </PageHeader>

      <div class="promotion-page__title">
        <div class="promotion-page__title">
          <span class="promotion-page__font promotion-page__font--title">
            {{ $t("general.promo.descriptionTitle") }}
          </span>
        </div>

        <div class="promotion-page__description">
          <span class="promotion-page__font promotion-page__font--description">
            {{ $t("general.promo.description") }}
          </span>
        </div>

        <b-row class="px-3 mt-3 d-block" v-if="!promo">
          <CButton theme="black" block @click.prevent="startPromotion">
            {{ $t("general.promo.startButton") }}
          </CButton>
        </b-row>
      </div>

      <div v-if="promo">
        <div class="promotion-page__title">
          <span class="promotion-page__font promotion-page__font--title">
            {{ $t("general.promo.currentTitle") }}
          </span>
        </div>
      </div>

      <div class="promotion-page__hr promotion-page__hr--1" v-if="promo" />

      <div class="post-list__posts" v-if="promo">
        <PromotionCard :card="promoCard" />
      </div>

      <div class="promotion-page__hr" v-if="promo" />

      <div class="promotion-page__controls" v-if="promo">
        <div class="promotion-controls">
          <div class="promotion-controls__stat">
            <div class="promotion-controls__col">
              <div class="promotion-controls__head">
                <span
                  class="promotion-controls__font promotion-controls__font--head"
                >
                  {{ date.start }}
                </span>
              </div>

              <div class="promotion-controls__bottom">
                <span
                  class="promotion-controls__font promotion-controls__font--bottom"
                >
                  {{ $t("general.promo.started") }}
                </span>
              </div>
            </div>

            <div class="promotion-controls__col">
              <div class="promotion-controls__head">
                <span
                  class="promotion-controls__font promotion-controls__font--head"
                >
                  {{ promo.total_claimed }}
                </span>
              </div>

              <div class="promotion-controls__bottom">
                <span
                  class="promotion-controls__font promotion-controls__font--bottom"
                >
                  {{ $t("general.promo.claimed") }}
                </span>
              </div>
            </div>
          </div>

          <div class="promotion-controls__buttons">
            <button
              type="submit"
              @click.prevent="stop"
              :disabled="!promo.is_active"
              class="base-button base-button--danger"
              :class="{ 'base-button--disabled': !promo.is_active }"
            >
              <span class="base-button__font">
                {{ $t("general.promo.stop") }}
              </span>
            </button>

            <button
              type="submit"
              @click.prevent="edit"
              class="base-button base-button--white"
            >
              <span class="base-button__font base-button__font--black">
                {{ $t("general.promo.edit") }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import CIcon from "@/features/ui/CIcon.vue";
import PageHeader from "@/layout/PageHeader.vue";

import { mapActions, mapGetters } from "vuex";
import CButton from "@/features/ui/СButton.vue";
import { formatDateTime } from "@/tools/helpers";
import PromotionCard from "@/features/components/PromotionCard.vue";
import User from "@/components/models/User";

export default {
  name: "PagePromotions",
  components: {
    PromotionCard,
    CButton,
    PageHeader,
    CIcon,
  },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({
      user: "user",
      promo: "promo/promo",
    }),
    promoCard() {
      return {
        promo: this.promo,
        avatar: this.user.avatar,
      }
    },
    isCreatorRole() {
      return this.user.role.includes(User.ROLE_CREATOR);
    },
    date() {
      return {
        end: this.formatDateTime(this.promo.end_at, {
          month: "short",
          day: "2-digit",
        }).date,
        start: this.formatDateTime(this.promo.start_at, {
          month: "short",
          day: "2-digit",
        }).date,
      };
    },
  },
  created() {
    if(!this.isCreatorRole) {
      this.$router.push('/404')
    }
  },
  methods: {
    formatDateTime,
    ...mapActions({
      stopPromo: "promo/stopPromo",
    }),

    startPromotion() {
      this.$popup.open("CreatePromotionPopup");
    },

    stop() {
      if (this.requestInProgress) return;

      this.waitRequest(() => {
        return this.stopPromo().catch(this.checkErrors);
      });
    },

    edit() {
      this.$popup.open("CreatePromotionPopup");
    },
  },
};
</script>

<style scoped lang="scss">
.promotion-page {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
  }

  &__title {
    margin-top: 18px;
  }
  &__description {
    margin-top: 8px;
  }

  &__font {
    &--title {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 18px;
      color: $app-gray-26;
    }

    &--description {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      line-height: 129%;
      color: $app-gray-27;
    }
  }

  &__controls {
    margin-top: 8px;
  }

  &__hr {
    margin-left: em(-15);
    margin-right: em(-15);
    border-bottom: 1px solid $app-gray-2;
    margin-top: 14px;

    &--1 {
      margin-top: 14px;
    }
  }
}

.collections-header-button {
  &__button {
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.user-tab {
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-7;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.post-list {
  margin-right: -15px;
  margin-left: -15px;

  &__posts {
    margin-top: 1px;
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;

    &--black {
      color: $black;
    }
  }

  &--danger {
    border: 1px solid transparent;
    background-color: $app-red-4;
  }

  &--white {
    border: 1px solid transparent;
    background-color: $white;
  }

  &--disabled {
  }
}

// new

.collections-header-button {
  &__button {
  }

  &__icon {
  }
}

.px-3 {
}

.mt-3 {
}

.d-block {
}

.post-list {
  &__posts {
  }
}

.promotion-controls {
  background-color: $app-gray-26;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__stat {
    display: flex;
    align-items: center;
    gap: 21px;
  }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__head {
  }

  &__font {
    color: $white;
    text-transform: uppercase;

    &--head {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      color: $white;
    }

    &--bottom {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 10px;
      color: $app-gray-30;
    }
  }

  &__bottom {
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>
